<template>
  <v-dialog v-model="show" v-if="videoData" width="auto " persistent>
    <v-card class="cardModalCon">
      <v-card-title class="titleCon">
        <div class="titleSec">{{ videoData.title }}</div>
        <v-btn class="closeBtn" icon small outlined @click.stop="closeVedio()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="conOfVideo conOfIframe mt-2">
          <youtube :video-id="videoData.id" ref="youtube"></youtube>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "videoData"],
  methods: {
    closeVedio() {
      this.show = false;
      this.$refs.youtube.player.pauseVideo()
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
      player() {
        return this.$refs.youtube.player;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_vedio-Modal.scss";
</style>
