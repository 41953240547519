<template>
  <section class="conOfVideosGallery h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Videos', part2: 'Gallery' }" />
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>

    <section class="mt-3" v-if="mediaCenter && mediaCenter.length > 0">
      <v-container>
        <v-row>
          <v-col
            lg="3"
            md="4"
            sm="6"
            cols="12"
            v-for="(media, index) in mediaCenter"
            :key="index"
          >
            <div
              class="conOfVideo"
              @click.prevent="openVedio(media.url, media.title)"
            >
              <v-card class="mx-auto" max-width="374" tile elevation="1">
                <v-img
                  height="250"
                  :src="'https://img.youtube.com/vi/' + media.url + '/0.jpg'"
                  alt="item of the Image"
                ></v-img>

                <v-card-title class="cardTitle">{{ media.title }}</v-card-title>

                <v-card-text>
                  <div class="cardDesc">
                    {{ media.description }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
    <VedioModal v-model="dialog" :videoData="videoData" />
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import VedioModal from "./vedio-Modal";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "Videos",
        disabled: false,
      },
    ],
    updatedCurrent_page: null,
    mediaCenter: null,
    isLoading: false,
    isLoadingSearch: false,
    videoData: {
      id: null,
      title: null,
    },
  }),
  components: {
    EmptyState,
    VedioModal,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "VideosGallery",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getmediaCenter();
      window.scrollTo(0, 0);
    },
    getmediaCenter() {
      this.mediaCenter = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post("media?page=" + this.pagination.current_page, this.search)
        .then((res) => {
          if (res) {
            this.mediaCenter = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
    openVedio(id, title) {
      this.dialog = true;
      this.videoData.id = id;
      this.videoData.title = title;
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        this.getmediaCenter();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_videos-gallery.scss";
</style>
